._profile {
  border-radius: 100px;
  margin-right: 20px;
}
.right-details {
  text-align: right;
}
.left-details {
  text-align: left;
  margin-bottom: -20px;
}
.paidStatus {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
  margin-top: 60px !important;
  border: 2px solid #dbd6d6;
}
._filter_view {
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
._filter_inner_view {
  display: flex;
  background-color: white;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
  margin: 10px;
  flex-wrap: wrap;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 120px;
}
.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}
