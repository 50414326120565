._drawerDropDown{
    border: none;
    width: 100%;
    padding: 2%;
    outline: none !important;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
}
._drawerPageLInk{
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
}
._siteTitle{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
    color: black;
}
.btn{
    box-shadow: none !important;
}

.link_color{
    color: black !important;
}

._side_drawer{
    background: linear-gradient(to right , #323891,#951d9a) !important;
    padding: 15px !important;
    background-color: white !important;
}
.iskillers_header{
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
._iskillers_logo{
    width: 100px;
    height: 100px;
}
._icon_btn{
    color: white !important;
    outline: none !important;
}
._iskillers_navbar_logo{
    /* width: 150px; */
    height: 40px;
}