.announcemenTitle {
  text-align: center;
  color: #293b90;
  font-size: large;
  font-size: 45px;
  font-weight: bold;
  padding: 20px;
  margin-top: 60px;
}
.Notifications {
  color: #293b90;
  text-align: right;
}
.emailDiv {
  text-align: right;
}
.announcementHeading {
  font-size: large;
  padding-top: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #801780;
}
