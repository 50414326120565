.title {
    text-align: left;
    font-weight: bold;
    color: #293b90;
    font-size: large;
  }
  ._body {
    margin: auto;
  }
  ._filter_view1 {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  ._student-filter {
    display: flex;
    background-color: white;
    flex-direction: row;
    align-items: center;
    padding: 15px !important;
    border-radius: 3px;
    width: 50%;
  }
  
  ._filter_title {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
  }
  
  .__dropdown {
    width: 150px !important;
    height: 30px;
    outline: none !important;
  }
  
  .button2 {
    margin-left: 10px;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
    background-color: gray;
    color: #293b90;
    font-weight: bold !important;
  }
  
  .button {
    margin-left: 10px;
    background: linear-gradient(to right, #293b90, #98189a) !important;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px !important;
    font-size: 13px;
    font-weight: bold;
    outline: none !important;
  }
  
  @media only screen and (max-width: 600px) {
    ._filter_view1 {
      text-align: center !important;
      align-items: center;
    }
    ._student-filter {
      width: 100%;
    }
    .title {
      text-align: left;
      margin-bottom: 10px !important;
      margin-top: 20px !important;
      font-weight: bold;
      color: #293b90;
      font-size: large;
    }
  
    .button {
      background: linear-gradient(to right, #293b90, #98189a) !important;
      color: white;
      border-width: 0px;
      padding-top: 7px;
      padding-bottom: 10px;
      padding-right: 10px;
      border-radius: 3px !important;
      font-size: 13px;
      font-weight: bold;
      text-align: center !important;
      float: right !important;
      margin-right: 10px;
    }
  }
  