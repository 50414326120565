._upersection{
    padding: 20px !important;
    display: flex;
    justify-content: space-between;
}
._grid{
    margin-top: 100px !important;
}
._upersection span{
  display: flex;
  flex: 1;
  text-align: left !important;
  /* border: 2px solid black; */
  padding-left:10px;
  
}

._input{
    width: 90%;
  padding: 1%;
  border: 1px solid #98189a;
  border-radius: 5px;
  outline: none;
  /* color: #98189a; */
  margin: auto;
  /* align-self: center; */
  margin-top: 20px;
}
._select_drop{
  /* border: 1px solid #98189a; */
  /* border: 1px solid #98189a; */
  outline: none !important;
  border-color: red;

}
.pro_image{
border-radius: 100px;
}
._filter_view1{
  
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: space-between;
  
}
._student-filter{
  display: flex;
  background-color: white;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* flex: 1; */
}

._dropdown{
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
  }

  
  .button{
    margin-left: 10px;
    background: linear-gradient(to right, #293B90, #98189a) !important;
    color: white;
    border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: bold;
    
  
  }


  @media only screen and (max-width: 600px) {
    ._upersection span{
     font-size: 10px;
    }
  }


  ._subscription_checkbox:checked{
    background-color: #98189a !important;
  }