._topics_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
  }
  ._edit_icon {
    outline: none !important;
    cursor: pointer;
    margin: 2px;
  }
  
  ._delete_icon {
    outline: none !important;
    cursor: pointer;
    margin: 2px;
  }
  
  ._topics_coloumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    margin-top: -10px;
  }
  ._save_btn {
    background-color: green;
    color: white;
    padding: 2px;
    width: 20px !important;
    border: none;
    margin: 3px;
    border-radius: 5px;
  }
  ._course_selection {
    padding: 5px;
    border: none;
    outline: none !important;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 800px) {
    ._main_container {
      margin-top: 100px;
    }
  }
  