@import url(https://fonts.googleapis.com/css2?family=Domine&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Goldman&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap);

body {
  margin: 0;
  font-family: 'Domine', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

._popup {
  width: 130px;
  position: absolute;
  right: 0;
  border: 2px solid;
  margin-top: 60px;
  background-color: white;
  /* display: none; */
}

.email {
  width: 90%;
}
.password {
  width: 90%;
}
.loginBtn {
  width: 30%;
}
.signupText {
  font-weight: 500;
}
._tabbtn {
  width: 100%;
}
._header_Section {
  height: 200px;
  background-color: #293b90;
  border-bottom: 4px solid white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.admin_icon {
  font-size: 140px !important;
  color: white;
}

@media only screen and (max-width: 600px) {
  .admin_icon {
    font-size: 100px !important;
    color: white;
  }
  ._header_Section {
    height: 130px;
  }
}


._drawerDropDown{
    border: none;
    width: 100%;
    padding: 2%;
    outline: none !important;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
}
._drawerPageLInk{
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
}
._siteTitle{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
    color: black;
}
.btn{
    box-shadow: none !important;
}

.link_color{
    color: black !important;
}

._side_drawer{
    background: linear-gradient(to right , #323891,#951d9a) !important;
    padding: 15px !important;
    background-color: white !important;
}
.iskillers_header{
    height: 15vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
._iskillers_logo{
    width: 100px;
    height: 100px;
}
._icon_btn{
    color: white !important;
    outline: none !important;
}
._iskillers_navbar_logo{
    /* width: 150px; */
    height: 40px;
}
._stateDiv {
  background-color: white;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 10% !important;
  padding: 30px;
  margin-top: 20px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 50px;
  font-weight: bold;
}

.countCard {
  background-color: rgb(236, 231, 240);
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 10% !important;
  margin-top: 50px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 50px;
  width: 80%;
  height: 120px;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  cursor: pointer;
}
._stateDiv:hover {
  background-color: #a0e0c6 !important;
  color: white;
}

._upgrade_batch {
  background-color: white;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 40px;
  margin: auto;
  width: 94% !important;
  margin-top: 40px;
  padding: 15px;
}
._upgrade_batch:hover {
  background-color: #a0d4ff;
}

._icon1 {
  height: 30px;
  width: 30px;
}
._icon2 {
  height: 30px;
  width: 30px;
}
._icon3 {
  height: 30px;
  width: 30px;
}
.upgrade_icon {
  height: 30px;
  width: 30px;
}
._text {
  padding-left: 10px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px !important;
}
._numbering {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
}

.counter {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  font-family: sans-serif;
  -webkit-justify-content: center;
          justify-content: center;
}
._countertext {
  font-size: 20px;
  font-family: sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 17px;
  cursor: pointer;
}
._topView {
  box-shadow: 1px 7px 13px 6px rgb(148, 148, 148);
}

._container_sec {
  margin-top: 50px;
}

@media only screen and (max-width: 600px) {
  ._text {
    font-size: 12px !important;
  }
}

._profile {
  border-radius: 100px;
  margin-right: 20px;
}
.right-details {
  text-align: right;
}
.left-details {
  text-align: left;
  margin-bottom: -20px;
}
.paidStatus {
  /* text-align: center; */
  display: -webkit-flex;
  display: flex;
  /* justify-content: center; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.toggle_outline {
  outline: none !important;
}
._status_div {
  width: 160px;
  margin-left: 100px;
}


.title {
  text-align: left;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px !important;
  border-radius: 3px;
  width: 50%;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

.__dropdown {
  width: 150px !important;
  height: 30px;
  outline: none !important;
}

.button2 {
  margin-left: 10px;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  background-color: gray;
  color: #293b90;
  font-weight: bold !important;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px !important;
  font-size: 13px;
  font-weight: bold;
  outline: none !important;
}

@media only screen and (max-width: 600px) {
  ._filter_view1 {
    text-align: center !important;
    -webkit-align-items: center;
            align-items: center;
  }
  ._student-filter {
    width: 100%;
  }
  .title {
    text-align: left;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    font-weight: bold;
    color: #293b90;
    font-size: large;
  }

  .button {
    background: linear-gradient(to right, #293b90, #98189a) !important;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-radius: 3px !important;
    font-size: 13px;
    font-weight: bold;
    text-align: center !important;
    float: right !important;
    margin-right: 10px;
  }
}

.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
}
._filter_view {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._filter_inner_view {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 120px;
}
.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px !important;
  font-size: 13px;
  font-weight: bold;
}

.onlinetitle {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 60px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
}
._filter_view {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._filter_view2 {
  margin-top: 80px;
}
._filter_inner_view {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 120px;
}
.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

.title{
    text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293B90;
  font-size: large;
   
  
  }
  ._body{
    width: 80%;
    margin: auto;
  }
  ._filter_view1{
    text-align: right;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    
  }
  ._student-filter{
    display: -webkit-flex;
    display: flex;
    background-color: white;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 7px;
    border-radius: 3px;
    margin: 10px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    /* flex: 1; */
  }
  
  ._filter_title{
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
  }
  
  ._dropdown{
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
  }

  
  .button{
    margin-left: 10px;
    background: linear-gradient(to right, #293B90, #98189a) !important;
    color: white;
    border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: bold;
    
  
  }
.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

.title {
  text-align: left;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px !important;
  border-radius: 3px;
  width: 50%;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

.__dropdown {
  width: 150px !important;
  height: 30px;
  outline: none !important;
}

.button2 {
  margin-left: 10px;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  background-color: gray;
  color: #293b90;
  font-weight: bold !important;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px !important;
  font-size: 13px;
  font-weight: bold;
  outline: none !important;
}

@media only screen and (max-width: 600px) {
  ._filter_view1 {
    text-align: center !important;
    -webkit-align-items: center;
            align-items: center;
  }
  ._student-filter {
    width: 100%;
  }
  .title {
    text-align: left;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    font-weight: bold;
    color: #293b90;
    font-size: large;
  }

  .button {
    background: linear-gradient(to right, #293b90, #98189a) !important;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-radius: 3px !important;
    font-size: 13px;
    font-weight: bold;
    text-align: center !important;
    float: right !important;
    margin-right: 10px;
  }
}

.title {
  text-align: left;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px !important;
  border-radius: 3px;
  width: 50%;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

.__dropdown {
  width: 150px !important;
  height: 30px;
  outline: none !important;
}

.button2 {
  margin-left: 10px;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  background-color: gray;
  color: #293b90;
  font-weight: bold !important;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px !important;
  font-size: 13px;
  font-weight: bold;
  outline: none !important;
}

@media only screen and (max-width: 600px) {
  ._filter_view1 {
    text-align: center !important;
    -webkit-align-items: center;
            align-items: center;
  }
  ._student-filter {
    width: 100%;
  }
  .title {
    text-align: left;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    font-weight: bold;
    color: #293b90;
    font-size: large;
  }

  .button {
    background: linear-gradient(to right, #293b90, #98189a) !important;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-radius: 3px !important;
    font-size: 13px;
    font-weight: bold;
    text-align: center !important;
    float: right !important;
    margin-right: 10px;
  }
}

.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

._upersection{
    padding: 20px !important;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
._grid{
    margin-top: 100px !important;
}
._upersection span{
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: left !important;
  /* border: 2px solid black; */
  padding-left:10px;
  
}

._input{
    width: 90%;
  padding: 1%;
  border: 1px solid #98189a;
  border-radius: 5px;
  outline: none;
  /* color: #98189a; */
  margin: auto;
  /* align-self: center; */
  margin-top: 20px;
}
._select_drop{
  /* border: 1px solid #98189a; */
  /* border: 1px solid #98189a; */
  outline: none !important;
  border-color: red;

}
.pro_image{
border-radius: 100px;
}
._filter_view1{
  
  display: -webkit-flex;
  
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  
  -webkit-align-items: center;
  
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  
}
._student-filter{
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  /* flex: 1; */
}

._dropdown{
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
  }

  
  .button{
    margin-left: 10px;
    background: linear-gradient(to right, #293B90, #98189a) !important;
    color: white;
    border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: bold;
    
  
  }


  @media only screen and (max-width: 600px) {
    ._upersection span{
     font-size: 10px;
    }
  }


  ._subscription_checkbox:checked{
    background-color: #98189a !important;
  }
.main_div {
  margin-top: 10%;
}
._title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

._profile {
  border-radius: 100px;
  margin-right: 20px;
}
.right-details {
  text-align: right;
}
.left-details {
  text-align: left;
  margin-bottom: -20px;
}
.paidStatus {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.title {
  text-align: left;
  margin-bottom: -60px;
  margin-top: 100px;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  width: 80%;
  margin: auto;
  margin-top: 60px !important;
  border: 2px solid #dbd6d6;
}
._filter_view {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._filter_inner_view {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

._dropdown {
  width: 120px;
}
.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

._upersection {
  padding: 20px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
._grid {
  margin-top: 50px;
}

._input {
  width: 90%;
  padding: 1%;
  border: 1px solid #98189a;
  border-radius: 5px;
  outline: none;
  margin: auto;
  margin-top: 20px;
}
._select_drop {
  outline: none !important;
  border-color: red;
}
.pro_image {
  border-radius: 100px;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 7px;
  border-radius: 3px;
  margin: 10px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

._dropdown {
  width: 150px !important;
  margin-left: 30px;
  height: 30px;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
}

.announcemenTitle {
  text-align: center;
  color: #293b90;
  font-size: large;
  font-size: 45px;
  font-weight: bold;
  padding: 20px;
  margin-top: 60px;
}
.Notifications {
  color: #293b90;
  text-align: right;
}
.emailDiv {
  text-align: right;
}
.announcementHeading {
  font-size: large;
  padding-top: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #801780;
}

._topics_row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
  box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
}
._edit_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._delete_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._topics_coloumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 10px;
  margin-top: -10px;
}
._save_btn {
  background-color: green;
  color: white;
  padding: 2px;
  width: 20px !important;
  border: none;
  margin: 3px;
  border-radius: 5px;
}
._course_selection {
  padding: 5px;
  border: none;
  outline: none !important;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  ._main_container {
    margin-top: 100px;
  }
}

._topics_row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
  box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
}
._edit_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._delete_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._topics_coloumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 10px;
  margin-top: -10px;
}
._save_btn {
  background-color: green;
  color: white;
  padding: 2px;
  width: 20px !important;
  border: none;
  margin: 3px;
  border-radius: 5px;
}
._course_selection {
  padding: 5px;
  border: none;
  outline: none !important;
  margin-bottom: 10px;
}
._gallery_images {
  width: 95%;
  height: 200px;
  transition: 0.3s;
  cursor: pointer;
}

._gallery_images_div {
  text-align: center;
  margin: auto;
  padding: 20px;
  width: 100% !important;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: white;
  color: green;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 20px;
  border-left: 3px solid green;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@media screen and (max-width: 800px) {
  ._main_container {
    margin-top: 100px;
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.batche_save_btn {
  background-color: dodgerblue;
  color: #fff;
  border: 1px solid dodgerblue !important;
  outline: tomato !important;
  padding: 5px;
  width: 80px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.batche_save_btn {
  background-color: dodgerblue;
  color: #fff;
  border: 1px solid dodgerblue !important;
  outline: tomato !important;
  padding: 5px;
  width: 80px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.batche_save_btn {
  background-color: dodgerblue;
  color: #fff;
  border: 1px solid dodgerblue !important;
  outline: tomato !important;
  padding: 5px;
  width: 80px;
}
._discount_heading {
  text-align: left !important;
}

._topics_row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
  box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
}
._edit_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._delete_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._topics_coloumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 10px;
  margin-top: -10px;
}
._save_btn {
  background-color: green;
  color: white;
  padding: 2px;
  width: 20px !important;
  border: none;
  margin: 3px;
  border-radius: 5px;
}
._course_selection {
  padding: 5px;
  border: none;
  outline: none !important;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  ._main_container {
    margin-top: 100px;
  }
}

.title {
  text-align: left;
  font-weight: bold;
  color: #293b90;
  font-size: large;
}
._body {
  margin: auto;
}
._filter_view1 {
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
._student-filter {
  display: -webkit-flex;
  display: flex;
  background-color: white;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px !important;
  border-radius: 3px;
  width: 50%;
}

._filter_title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

.__dropdown {
  width: 150px !important;
  height: 30px;
  outline: none !important;
}

.button2 {
  margin-left: 10px;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  background-color: gray;
  color: #293b90;
  font-weight: bold !important;
}

.button {
  margin-left: 10px;
  background: linear-gradient(to right, #293b90, #98189a) !important;
  color: white;
  border-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px !important;
  font-size: 13px;
  font-weight: bold;
  outline: none !important;
}

@media only screen and (max-width: 600px) {
  ._filter_view1 {
    text-align: center !important;
    -webkit-align-items: center;
            align-items: center;
  }
  ._student-filter {
    width: 100%;
  }
  .title {
    text-align: left;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    font-weight: bold;
    color: #293b90;
    font-size: large;
  }

  .button {
    background: linear-gradient(to right, #293b90, #98189a) !important;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-radius: 3px !important;
    font-size: 13px;
    font-weight: bold;
    text-align: center !important;
    float: right !important;
    margin-right: 10px;
  }
}

.title {
    text-align: left;
    font-weight: bold;
    color: #293b90;
    font-size: large;
  }
  ._body {
    margin: auto;
  }
  ._filter_view1 {
    text-align: right;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
  }
  ._student-filter {
    display: -webkit-flex;
    display: flex;
    background-color: white;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 15px !important;
    border-radius: 3px;
    width: 50%;
  }
  
  ._filter_title {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
  }
  
  .__dropdown {
    width: 150px !important;
    height: 30px;
    outline: none !important;
  }
  
  .button2 {
    margin-left: 10px;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
    background-color: gray;
    color: #293b90;
    font-weight: bold !important;
  }
  
  .button {
    margin-left: 10px;
    background: linear-gradient(to right, #293b90, #98189a) !important;
    color: white;
    border-width: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px !important;
    font-size: 13px;
    font-weight: bold;
    outline: none !important;
  }
  
  @media only screen and (max-width: 600px) {
    ._filter_view1 {
      text-align: center !important;
      -webkit-align-items: center;
              align-items: center;
    }
    ._student-filter {
      width: 100%;
    }
    .title {
      text-align: left;
      margin-bottom: 10px !important;
      margin-top: 20px !important;
      font-weight: bold;
      color: #293b90;
      font-size: large;
    }
  
    .button {
      background: linear-gradient(to right, #293b90, #98189a) !important;
      color: white;
      border-width: 0px;
      padding-top: 7px;
      padding-bottom: 10px;
      padding-right: 10px;
      border-radius: 3px !important;
      font-size: 13px;
      font-weight: bold;
      text-align: center !important;
      float: right !important;
      margin-right: 10px;
    }
  }
  
._topics_row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
  }
  ._edit_icon {
    outline: none !important;
    cursor: pointer;
    margin: 2px;
  }
  
  ._delete_icon {
    outline: none !important;
    cursor: pointer;
    margin: 2px;
  }
  
  ._topics_coloumn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 10px;
    margin-top: -10px;
  }
  ._save_btn {
    background-color: green;
    color: white;
    padding: 2px;
    width: 20px !important;
    border: none;
    margin: 3px;
    border-radius: 5px;
  }
  ._course_selection {
    padding: 5px;
    border: none;
    outline: none !important;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 800px) {
    ._main_container {
      margin-top: 100px;
    }
  }
  
._topics_row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
  }
  ._edit_icon {
    outline: none !important;
    cursor: pointer;
    margin: 2px;
  }
  
  ._delete_icon {
    outline: none !important;
    cursor: pointer;
    margin: 2px;
  }
  
  ._topics_coloumn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 10px;
    margin-top: -10px;
  }
  ._save_btn {
    background-color: green;
    color: white;
    padding: 2px;
    width: 20px !important;
    border: none;
    margin: 3px;
    border-radius: 5px;
  }
  ._course_selection {
    padding: 5px;
    border: none;
    outline: none !important;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 800px) {
    ._main_container {
      margin-top: 100px;
    }
  }
  
