._profile {
  border-radius: 100px;
  margin-right: 20px;
}
.right-details {
  text-align: right;
}
.left-details {
  text-align: left;
  margin-bottom: -20px;
}
.paidStatus {
  /* text-align: center; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.toggle_outline {
  outline: none !important;
}
._status_div {
  width: 160px;
  margin-left: 100px;
}
