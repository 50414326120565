._topics_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
  box-shadow: 1px 1px 4px 2px rgb(187, 183, 183);
}
._edit_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._delete_icon {
  outline: none !important;
  cursor: pointer;
  margin: 2px;
}

._topics_coloumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: -10px;
}
._save_btn {
  background-color: green;
  color: white;
  padding: 2px;
  width: 20px !important;
  border: none;
  margin: 3px;
  border-radius: 5px;
}
._course_selection {
  padding: 5px;
  border: none;
  outline: none !important;
  margin-bottom: 10px;
}
._gallery_images {
  width: 95%;
  height: 200px;
  transition: 0.3s;
  cursor: pointer;
}

._gallery_images_div {
  text-align: center;
  margin: auto;
  padding: 20px;
  width: 100% !important;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: white;
  color: green;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 20px;
  border-left: 3px solid green;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@media screen and (max-width: 800px) {
  ._main_container {
    margin-top: 100px;
  }
}
